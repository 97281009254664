import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.24.0_next@15.0.0-canary.144_@babel+core@7.26.0_babel-plugin-react-compiler@0.0.0-_icibckfdaksxz2boagftxape2a/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/.pnpm/next-intl@3.24.0_next@15.0.0-canary.144_@babel+core@7.26.0_babel-plugin-react-compiler@0.0.0-_icibckfdaksxz2boagftxape2a/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/atoms/RevTagInput.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/components/blocks/statistics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Accordion","AccordionItem","AccordionTrigger","AccordionContent"] */ "/vercel/path0/src/components/ui/accordion.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["HeroHighlight","Highlight"] */ "/vercel/path0/src/components/ui/hero-highlight.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["InfiniteMovingCards"] */ "/vercel/path0/src/components/ui/infinite-moving-cards.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ErrorBoundary"] */ "/vercel/path0/src/components/utils/ErrorBoundaries.tsx");
